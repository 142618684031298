<template>
  <CardConf
    :title="$tc('commision', 2)"
    icon=" $euro"
    size="1050"
    :only_active="true"
    @open="fetchComisions()"
    keys="comisions_active"
    :help="true"
    @save="saveComision"
  >
    <template>
      <v-row>
        <v-col cols="3" class="py-0">Tipo de comisión según</v-col>
        <v-col cols="2" class="py-0">
          <v-select
            :items="comision_types"
            v-model="comision_type"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3" class="py-0">Guardar predeterminada? </v-col>
        <v-col cols="2" class="py-0"
          ><v-select
            :items="yesNo"
            v-model="comision_has_definition"
            outlined
            dense
          ></v-select
        ></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title
              style="text-decoration: underline; text-transform: uppercase"
            >
              Comisiones predeterminadas
            </v-card-title>
            <v-card-text>
              <br />
              <v-row justify="center">
                <v-col cols="12" class="py-0">
                  <ComisionForm
                    ref="comisionForm"
                    :type="comision_type"
                    :disabled="comision_has_definition === 0 || disabled"
                    :commissions="commissions"
                  ></ComisionForm>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </CardConf>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AlertsCard",
  components: {
    ComisionForm: () => import("@/components/tattooer/CommissionsForm"),
    CardConf: () => import("@/components/user/settings/Card"),
  },

  methods: {
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    edit(valor) {
      this.disabled = valor;
    },
    fetchComisions() {
      //console.log("fetchComisions");
      this.getSetting({ key: "commisions_predeterminated" }).then((data) => {
        if (data.data) {
          this.commissions = data.data.value;
        }

        this.$refs.comisionForm.changeComisions();
      });
    },
    saveComision() {
      this.settingUpdate({
        key: "commisions_predeterminated",
        value: this.commissions,
        json: true,
      }).then(() => {
        this.fetchComisions();
      });
    },
  },
  data() {
    return {
      disabled: false,
      yesNo: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
      comision_types: [
        { text: this.$t("comision_types.from"), value: "from" },
        { text: this.$t("comision_types.price"), value: "price" },
        { text: this.$t("comision_types.both"), value: "both" },
      ],
      commissions: {
        price: null,
        type: null,
        comisions: [],
      },
    };
  },
  computed: {
    comision_type: {
      //tipo de comision
      get() {
        return this.$store.getters["auth/getSetting"]("comision_type");
      },
      set(value) {
        this.settingUpdate({ key: "comision_type", value }).then(() => {
          //console.log(this.$refs);
          this.$refs.comisionForm.changeComisions();
        });
      },
    },

    comision_has_definition: {
      //Puedes poner  comision Predeterminada
      get() {
        return this.$store.getters["auth/getSetting"](
          "comision_has_definition"
        );
      },
      set(value) {
        this.settingUpdate({ key: "comision_has_definition", value });
      },
    },
  },
};
</script>

